import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.API_URL,
});

axiosInstance.interceptors.request.use(config => {
  const token = window.localStorage.getItem('customerToken');
  // config.headers.client_id = process.env.CLIENT_ID;
  config.headers['Content-type'] = 'application/json';
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
}, null);

axiosInstance.interceptors.response.use(null, error => {
  const {
    // config,
    response: { status },
  } = error;

  if (status !== 401) {
    // Rejeita a promise com o erro original
    return Promise.reject(error);
  }

  // return axiosInstance
  //   .post('/oauth/access-token?grant_type=client_credentials', null, {
  //     headers: {
  //       Authorization: `Basic ${process.env.AUTHORIZATION}`,
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //     },
  //   })
  //   .then(res => {
  //     window.localStorage.setItem('access_token', res.data.access_token);
  //     return axiosInstance(config);
  //   });
});

export default axiosInstance;
